<template>
    <basic-page addedclass="white-bg" 
        :pagedata="this.pageData" 
        :bannerimg="backgroundImage">

        <div v-if="this.pageData.attributes.field_body_01" class="container-espe" 
            v-html="this.pageData.attributes.field_body_01.value">
        </div>

        <div class="container-espe" v-if="this.pageData.field_page_image_01">
            <images :imagedata="pageData" 
                :fieldname="'field_page_image_01'" 
                :breakstyles="{'0': 'sm_image', '576': 'md_image'}">
            </images>
        </div>

        <div v-if="this.pageData.attributes.field_body_01" class="container-espe" 
            v-html="this.pageData.attributes.field_body_02.value">
        </div>

        <div v-if="this.pageData.field_page_image_02" class="container-espe">
            <images :imgclass="'fullwidth-image'" 
                :imagedata="pageData" 
                :fieldname="'field_page_image_02'" 
                :breakstyles="{'0': 'sm_image', '576': 'md_image', '992': 'lg_image'}">
            </images>
        </div>
        
        <div v-if="this.pageData.attributes.field_body_03" class="container-espe"
            v-html="this.pageData.attributes.field_body_03.value">
        </div>

    </basic-page>
</template>

<script>
import BasicPage from './BasicPage.vue'
import Images from '../components/Images.vue'
import { fetchSingleNode, fetchSinglePage } from '../libs/drupalClient'
export default {
    components: { BasicPage, Images },
    name: 'il-rotary',
    data: () => {
        return {
            pageData: { 
                attributes: {
                    title: "",
                    body: {
                        summary: ""
                    }
                } 
            },
            currentPageId: "726eab25-ad00-490f-9d92-774eded68087",
            bannerData: {},
            metadata: {}
        }
    },
    
    computed: {
        image01() {
            return process.env.VUE_APP_ENDPOINT + this.pageData.field_page_image_01.attributes.uri.url
        },
        image02() {
            return process.env.VUE_APP_ENDPOINT + this.pageData.field_page_image_02.attributes.uri.url
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        },
        backgroundImage() {
            if(this.bannerData.field_banner_top)
                return this.bannerData
            else 
                return {}
        }
    },
    mounted() {
        fetchSinglePage('page_type_02', this.currentPageId, { include: ['field_page_image_01', 'field_page_image_02', 'field_ref_banner_top'] }, this.lang)
        .then(res => {
            this.pageData = res[0]

            fetchSingleNode('banner', this.pageData.field_ref_banner_top.id, { include: ['field_banner_top'] }, this.lang)
            .then(res => { 
                this.bannerData = res[0] 
            })
        })

        fetch(process.env.VUE_APP_ENDPOINT + '/meta?url=' + this.$route.fullPath)
        .then(res => {
            this.metadata = res
        })
    }
}
</script>

<style lang="scss" scoped>
</style>
